/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BasicCashAccount } from '../models/basic-cash-account';
import { BasicCompany } from '../models/basic-company';
import { BasicSecurityAccount } from '../models/basic-security-account';
import { BasicUser } from '../models/basic-user';
import { Group } from '../models/group';
import { SecurityAccountWithAssociatedCashAccounts } from '../models/security-account-with-associated-cash-accounts';
import { User } from '../models/user';
import { ViewMode } from '../models/view-mode';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCompanyById
   */
  static readonly GetCompanyByIdPath = '/v1/companies/{companyId}';

  /**
   * Get company by Id.
   *
   * Get company by Id.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyById$Response(params: {
    companyId: number;
  }): Observable<StrictHttpResponse<BasicCompany>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.GetCompanyByIdPath, 'get');
    if (params) {
      rb.path('companyId', params.companyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BasicCompany>;
      })
    );
  }

  /**
   * Get company by Id.
   *
   * Get company by Id.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCompanyById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyById(params: {
    companyId: number;
  }): Observable<BasicCompany> {

    return this.getCompanyById$Response(params).pipe(
      map((r: StrictHttpResponse<BasicCompany>) => r.body as BasicCompany)
    );
  }

  /**
   * Path part for operation getCompanies
   */
  static readonly GetCompaniesPath = '/v1/companies';

  /**
   * Get companies.
   *
   * Get companies.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanies()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanies$Response(params?: {
    viewMode?: ViewMode;
  }): Observable<StrictHttpResponse<Array<BasicCompany>>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.GetCompaniesPath, 'get');
    if (params) {
      rb.query('viewMode', params.viewMode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BasicCompany>>;
      })
    );
  }

  /**
   * Get companies.
   *
   * Get companies.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCompanies$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanies(params?: {
    viewMode?: ViewMode;
  }): Observable<Array<BasicCompany>> {

    return this.getCompanies$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BasicCompany>>) => r.body as Array<BasicCompany>)
    );
  }

  /**
   * Path part for operation getDelegatedCompanies
   */
  static readonly GetDelegatedCompaniesPath = '/v1/companies/{companyId}/delegations';

  /**
   * Get company delegations.
   *
   * Get company delegations.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDelegatedCompanies()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDelegatedCompanies$Response(params: {
    companyId: number;
  }): Observable<StrictHttpResponse<Array<BasicCompany>>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.GetDelegatedCompaniesPath, 'get');
    if (params) {
      rb.path('companyId', params.companyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BasicCompany>>;
      })
    );
  }

  /**
   * Get company delegations.
   *
   * Get company delegations.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDelegatedCompanies$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDelegatedCompanies(params: {
    companyId: number;
  }): Observable<Array<BasicCompany>> {

    return this.getDelegatedCompanies$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BasicCompany>>) => r.body as Array<BasicCompany>)
    );
  }

  /**
   * Path part for operation assignAdminsToCompany
   */
  static readonly AssignAdminsToCompanyPath = '/v1/companies/{companyId}/administrators';

  /**
   * Get company administrators.
   *
   * Get company administrators.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignAdminsToCompany()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  assignAdminsToCompany$Response(params: {
    companyId: number;
    body?: Array<User>
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.AssignAdminsToCompanyPath, 'put');
    if (params) {
      rb.path('companyId', params.companyId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Get company administrators.
   *
   * Get company administrators.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `assignAdminsToCompany$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  assignAdminsToCompany(params: {
    companyId: number;
    body?: Array<User>
  }): Observable<void> {

    return this.assignAdminsToCompany$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getManagedCompanies
   */
  static readonly GetManagedCompaniesPath = '/v1/companies/me/managed';

  /**
   * Get managed companies.
   *
   * Get managed companies.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getManagedCompanies()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManagedCompanies$Response(params?: {
  }): Observable<StrictHttpResponse<Array<BasicCompany>>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.GetManagedCompaniesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BasicCompany>>;
      })
    );
  }

  /**
   * Get managed companies.
   *
   * Get managed companies.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getManagedCompanies$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManagedCompanies(params?: {
  }): Observable<Array<BasicCompany>> {

    return this.getManagedCompanies$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BasicCompany>>) => r.body as Array<BasicCompany>)
    );
  }

  /**
   * Path part for operation getUsersByCompanyId
   */
  static readonly GetUsersByCompanyIdPath = '/v1/companies/{companyId}/users';

  /**
   * Get users by company id.
   *
   * Get users by company id.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsersByCompanyId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsersByCompanyId$Response(params: {
    companyId: number;
  }): Observable<StrictHttpResponse<Array<BasicUser>>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.GetUsersByCompanyIdPath, 'get');
    if (params) {
      rb.path('companyId', params.companyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BasicUser>>;
      })
    );
  }

  /**
   * Get users by company id.
   *
   * Get users by company id.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUsersByCompanyId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsersByCompanyId(params: {
    companyId: number;
  }): Observable<Array<BasicUser>> {

    return this.getUsersByCompanyId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BasicUser>>) => r.body as Array<BasicUser>)
    );
  }

  /**
   * Path part for operation getCashAccountsByCompanyId
   */
  static readonly GetCashAccountsByCompanyIdPath = '/v1/companies/{companyId}/cash-accounts';

  /**
   * Get CashAccounts By Company Id.
   *
   * Get CashAccounts By Company Id.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCashAccountsByCompanyId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCashAccountsByCompanyId$Response(params: {
    companyId: number;
    excludeClosedAccounts?: boolean;
  }): Observable<StrictHttpResponse<Array<BasicCashAccount>>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.GetCashAccountsByCompanyIdPath, 'get');
    if (params) {
      rb.path('companyId', params.companyId, {});
      rb.query('excludeClosedAccounts', params.excludeClosedAccounts, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BasicCashAccount>>;
      })
    );
  }

  /**
   * Get CashAccounts By Company Id.
   *
   * Get CashAccounts By Company Id.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCashAccountsByCompanyId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCashAccountsByCompanyId(params: {
    companyId: number;
    excludeClosedAccounts?: boolean;
  }): Observable<Array<BasicCashAccount>> {

    return this.getCashAccountsByCompanyId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BasicCashAccount>>) => r.body as Array<BasicCashAccount>)
    );
  }

  /**
   * Path part for operation getGroupsByCompanyId
   */
  static readonly GetGroupsByCompanyIdPath = '/v1/companies/{companyId}/groups';

  /**
   * Get groups by company id.
   *
   * Get groups by company id.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGroupsByCompanyId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGroupsByCompanyId$Response(params: {
    companyId: number;
  }): Observable<StrictHttpResponse<Array<Group>>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.GetGroupsByCompanyIdPath, 'get');
    if (params) {
      rb.path('companyId', params.companyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Group>>;
      })
    );
  }

  /**
   * Get groups by company id.
   *
   * Get groups by company id.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getGroupsByCompanyId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGroupsByCompanyId(params: {
    companyId: number;
  }): Observable<Array<Group>> {

    return this.getGroupsByCompanyId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Group>>) => r.body as Array<Group>)
    );
  }

  /**
   * Path part for operation checkGroupNameExist
   */
  static readonly CheckGroupNameExistPath = '/v1/companies/{companyId}/groups/{groupName}/{groupId}';

  /**
   * Check if group name exist.
   *
   * Check if group name exist.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkGroupNameExist()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkGroupNameExist$Response(params: {
    companyId: number;
    groupName: string;
    groupId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.CheckGroupNameExistPath, 'get');
    if (params) {
      rb.path('companyId', params.companyId, {});
      rb.path('groupName', params.groupName, {});
      rb.path('groupId', params.groupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Check if group name exist.
   *
   * Check if group name exist.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `checkGroupNameExist$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkGroupNameExist(params: {
    companyId: number;
    groupName: string;
    groupId: number;
  }): Observable<void> {

    return this.checkGroupNameExist$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getSecurityAccountsByCompanyId
   */
  static readonly GetSecurityAccountsByCompanyIdPath = '/v1/companies/{companyId}/security-accounts';

  /**
   * Get security accounts by company id.
   *
   * Get security accounts by company id.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSecurityAccountsByCompanyId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSecurityAccountsByCompanyId$Response(params: {
    companyId: number;
    excludeClosedAccounts?: boolean;
  }): Observable<StrictHttpResponse<Array<BasicSecurityAccount>>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.GetSecurityAccountsByCompanyIdPath, 'get');
    if (params) {
      rb.path('companyId', params.companyId, {});
      rb.query('excludeClosedAccounts', params.excludeClosedAccounts, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BasicSecurityAccount>>;
      })
    );
  }

  /**
   * Get security accounts by company id.
   *
   * Get security accounts by company id.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSecurityAccountsByCompanyId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSecurityAccountsByCompanyId(params: {
    companyId: number;
    excludeClosedAccounts?: boolean;
  }): Observable<Array<BasicSecurityAccount>> {

    return this.getSecurityAccountsByCompanyId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BasicSecurityAccount>>) => r.body as Array<BasicSecurityAccount>)
    );
  }

  /**
   * Path part for operation getAssociatedAccounts
   */
  static readonly GetAssociatedAccountsPath = '/v1/companies/{companyId}/security-accounts/associated-cash-accounts';

  /**
   * Get associated cash accounts by company id.
   *
   * Get associated cash accounts by company id.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAssociatedAccounts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAssociatedAccounts$Response(params: {
    companyId: number;
  }): Observable<StrictHttpResponse<Array<SecurityAccountWithAssociatedCashAccounts>>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.GetAssociatedAccountsPath, 'get');
    if (params) {
      rb.path('companyId', params.companyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SecurityAccountWithAssociatedCashAccounts>>;
      })
    );
  }

  /**
   * Get associated cash accounts by company id.
   *
   * Get associated cash accounts by company id.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAssociatedAccounts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAssociatedAccounts(params: {
    companyId: number;
  }): Observable<Array<SecurityAccountWithAssociatedCashAccounts>> {

    return this.getAssociatedAccounts$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SecurityAccountWithAssociatedCashAccounts>>) => r.body as Array<SecurityAccountWithAssociatedCashAccounts>)
    );
  }

  /**
   * Path part for operation exportGroupsByCompanyId
   */
  static readonly ExportGroupsByCompanyIdPath = '/v1/companies/{companyId}/export';

  /**
   * Export groups by company Id.
   *
   * Export groups by company Id.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportGroupsByCompanyId()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportGroupsByCompanyId$Response(params: {
    companyId: number;
    language?: string;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ExportGroupsByCompanyIdPath, 'post');
    if (params) {
      rb.path('companyId', params.companyId, {});
      rb.query('language', params.language, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<Blob>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Export groups by company Id.
   *
   * Export groups by company Id.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportGroupsByCompanyId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportGroupsByCompanyId(params: {
    companyId: number;
    language?: string;
  }): Observable<Blob> {

    return this.exportGroupsByCompanyId$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

}
