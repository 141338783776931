/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BasicCashAccount } from '../models/basic-cash-account';

@Injectable({
  providedIn: 'root',
})
export class CashAccountService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCashAccountsByUserId
   */
  static readonly GetCashAccountsByUserIdPath = '/v1/cash-accounts';

  /**
   * Get CashAccounts By User Id.
   *
   * Get CashAccounts By User Id.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCashAccountsByUserId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCashAccountsByUserId$Response(params?: {
    excludeClosedAccounts?: boolean;
  }): Observable<StrictHttpResponse<Array<BasicCashAccount>>> {

    const rb = new RequestBuilder(this.rootUrl, CashAccountService.GetCashAccountsByUserIdPath, 'get');
    if (params) {
      rb.query('excludeClosedAccounts', params.excludeClosedAccounts, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BasicCashAccount>>;
      })
    );
  }

  /**
   * Get CashAccounts By User Id.
   *
   * Get CashAccounts By User Id.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCashAccountsByUserId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCashAccountsByUserId(params?: {
    excludeClosedAccounts?: boolean;
  }): Observable<Array<BasicCashAccount>> {

    return this.getCashAccountsByUserId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BasicCashAccount>>) => r.body as Array<BasicCashAccount>)
    );
  }

}
