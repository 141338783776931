/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CashAccountUserGroup } from '../models/cash-account-user-group';

@Injectable({
  providedIn: 'root',
})
export class CashAccountUserGroupService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getUserGroups
   */
  static readonly GetUserGroupsPath = '/v1/cash-account-groups';

  /**
   * Get Cash Account user groups.
   *
   * Get Cash Account user groups.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserGroups()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserGroups$Response(params?: {
  }): Observable<StrictHttpResponse<Array<CashAccountUserGroup>>> {

    const rb = new RequestBuilder(this.rootUrl, CashAccountUserGroupService.GetUserGroupsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CashAccountUserGroup>>;
      })
    );
  }

  /**
   * Get Cash Account user groups.
   *
   * Get Cash Account user groups.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserGroups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserGroups(params?: {
  }): Observable<Array<CashAccountUserGroup>> {

    return this.getUserGroups$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CashAccountUserGroup>>) => r.body as Array<CashAccountUserGroup>)
    );
  }

  /**
   * Path part for operation add
   */
  static readonly AddPath = '/v1/cash-account-groups';

  /**
   * Create Cash Account user group.
   *
   * Create Cash Account user group.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `add()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  add$Response(params?: {
    body?: CashAccountUserGroup
  }): Observable<StrictHttpResponse<CashAccountUserGroup>> {

    const rb = new RequestBuilder(this.rootUrl, CashAccountUserGroupService.AddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CashAccountUserGroup>;
      })
    );
  }

  /**
   * Create Cash Account user group.
   *
   * Create Cash Account user group.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `add$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  add(params?: {
    body?: CashAccountUserGroup
  }): Observable<CashAccountUserGroup> {

    return this.add$Response(params).pipe(
      map((r: StrictHttpResponse<CashAccountUserGroup>) => r.body as CashAccountUserGroup)
    );
  }

  /**
   * Path part for operation delete
   */
  static readonly DeletePath = '/v1/cash-account-groups/{groupId}';

  /**
   * Delete Cash Account user group.
   *
   * Delete Cash Account user group.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete$Response(params: {
    groupId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CashAccountUserGroupService.DeletePath, 'delete');
    if (params) {
      rb.path('groupId', params.groupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete Cash Account user group.
   *
   * Delete Cash Account user group.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete(params: {
    groupId: number;
  }): Observable<void> {

    return this.delete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
