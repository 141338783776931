/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SecurityAccountUserGroup } from '../models/security-account-user-group';

@Injectable({
  providedIn: 'root',
})
export class SecurityAccountUserGroupService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getUserGroups_1
   */
  static readonly GetUserGroups_1Path = '/v1/security-account-groups';

  /**
   * Get Security Account user groups.
   *
   * Get Security Account user groups.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserGroups_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserGroups_1$Response(params?: {
  }): Observable<StrictHttpResponse<Array<SecurityAccountUserGroup>>> {

    const rb = new RequestBuilder(this.rootUrl, SecurityAccountUserGroupService.GetUserGroups_1Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SecurityAccountUserGroup>>;
      })
    );
  }

  /**
   * Get Security Account user groups.
   *
   * Get Security Account user groups.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserGroups_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserGroups_1(params?: {
  }): Observable<Array<SecurityAccountUserGroup>> {

    return this.getUserGroups_1$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SecurityAccountUserGroup>>) => r.body as Array<SecurityAccountUserGroup>)
    );
  }

  /**
   * Path part for operation add_1
   */
  static readonly Add_1Path = '/v1/security-account-groups';

  /**
   * Create Security Account user group.
   *
   * Create Security Account user group.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `add_1()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  add_1$Response(params?: {

    /**
     * Group to add
     */
    body?: SecurityAccountUserGroup
  }): Observable<StrictHttpResponse<SecurityAccountUserGroup>> {

    const rb = new RequestBuilder(this.rootUrl, SecurityAccountUserGroupService.Add_1Path, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SecurityAccountUserGroup>;
      })
    );
  }

  /**
   * Create Security Account user group.
   *
   * Create Security Account user group.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `add_1$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  add_1(params?: {

    /**
     * Group to add
     */
    body?: SecurityAccountUserGroup
  }): Observable<SecurityAccountUserGroup> {

    return this.add_1$Response(params).pipe(
      map((r: StrictHttpResponse<SecurityAccountUserGroup>) => r.body as SecurityAccountUserGroup)
    );
  }

  /**
   * Path part for operation delete_1
   */
  static readonly Delete_1Path = '/v1/security-account-groups/{groupId}';

  /**
   * Delete Security Account user group.
   *
   * Delete Security Account user group.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete_1$Response(params: {

    /**
     * id of group to delete
     */
    groupId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SecurityAccountUserGroupService.Delete_1Path, 'delete');
    if (params) {
      rb.path('groupId', params.groupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete Security Account user group.
   *
   * Delete Security Account user group.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `delete_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete_1(params: {

    /**
     * id of group to delete
     */
    groupId: number;
  }): Observable<void> {

    return this.delete_1$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
