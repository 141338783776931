import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{
		path: 'scheduled-reports',
		loadChildren: () => import('./scheduled-reports/scheduled-reports.module').then((m) => m.ScheduledReportsModule)
	},
	{ path: '', redirectTo: 'scheduled-reports', pathMatch: 'full' },
	{ path: '**', redirectTo: 'scheduled-reports' }
];
@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true })],
	exports: [RouterModule],
	providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }]
})
export class AppRoutingModule { }
