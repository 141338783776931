/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Group } from '../models/group';
import { GroupDelegationImpact } from '../models/group-delegation-impact';

@Injectable({
  providedIn: 'root',
})
export class GroupService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation addGroup
   */
  static readonly AddGroupPath = '/v1/groups';

  /**
   * Create a group.
   *
   * Create a group.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addGroup()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addGroup$Response(params?: {
    body?: Group
  }): Observable<StrictHttpResponse<Group>> {

    const rb = new RequestBuilder(this.rootUrl, GroupService.AddGroupPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Group>;
      })
    );
  }

  /**
   * Create a group.
   *
   * Create a group.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addGroup$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addGroup(params?: {
    body?: Group
  }): Observable<Group> {

    return this.addGroup$Response(params).pipe(
      map((r: StrictHttpResponse<Group>) => r.body as Group)
    );
  }

  /**
   * Path part for operation updateGroup
   */
  static readonly UpdateGroupPath = '/v1/groups/{groupId}';

  /**
   * Update a group.
   *
   * Update a group.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateGroup()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateGroup$Response(params: {
    groupId: number;
    body?: Group
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, GroupService.UpdateGroupPath, 'put');
    if (params) {
      rb.path('groupId', params.groupId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update a group.
   *
   * Update a group.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateGroup$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateGroup(params: {
    groupId: number;
    body?: Group
  }): Observable<void> {

    return this.updateGroup$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deleteGroup
   */
  static readonly DeleteGroupPath = '/v1/groups/{groupId}';

  /**
   * Delete a group.
   *
   * Delete a group.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteGroup$Response(params: {
    groupId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, GroupService.DeleteGroupPath, 'delete');
    if (params) {
      rb.path('groupId', params.groupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete a group.
   *
   * Delete a group.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteGroup(params: {
    groupId: number;
  }): Observable<void> {

    return this.deleteGroup$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation checkDelegationImpacts
   */
  static readonly CheckDelegationImpactsPath = '/v1/groups/{groupId}/impacts';

  /**
   * Get group impacts.
   *
   * Get group impacts.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkDelegationImpacts()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  checkDelegationImpacts$Response(params: {
    groupId: number;
    body?: Group
  }): Observable<StrictHttpResponse<Array<GroupDelegationImpact>>> {

    const rb = new RequestBuilder(this.rootUrl, GroupService.CheckDelegationImpactsPath, 'put');
    if (params) {
      rb.path('groupId', params.groupId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GroupDelegationImpact>>;
      })
    );
  }

  /**
   * Get group impacts.
   *
   * Get group impacts.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `checkDelegationImpacts$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  checkDelegationImpacts(params: {
    groupId: number;
    body?: Group
  }): Observable<Array<GroupDelegationImpact>> {

    return this.checkDelegationImpacts$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GroupDelegationImpact>>) => r.body as Array<GroupDelegationImpact>)
    );
  }

  /**
   * Path part for operation getGroupWithDetails
   */
  static readonly GetGroupWithDetailsPath = '/v1/groups/{groupId}/details';

  /**
   * Get group with details.
   *
   * Get group with details.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGroupWithDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGroupWithDetails$Response(params: {
    groupId: number;
  }): Observable<StrictHttpResponse<Group>> {

    const rb = new RequestBuilder(this.rootUrl, GroupService.GetGroupWithDetailsPath, 'get');
    if (params) {
      rb.path('groupId', params.groupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Group>;
      })
    );
  }

  /**
   * Get group with details.
   *
   * Get group with details.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getGroupWithDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGroupWithDetails(params: {
    groupId: number;
  }): Observable<Group> {

    return this.getGroupWithDetails$Response(params).pipe(
      map((r: StrictHttpResponse<Group>) => r.body as Group)
    );
  }

  /**
   * Path part for operation getNextGroupWithDetails
   */
  static readonly GetNextGroupWithDetailsPath = '/v1/groups/{groupId}/details/next';

  /**
   * Get next group with details.
   *
   * Get next group with details.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNextGroupWithDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNextGroupWithDetails$Response(params: {
    groupId: number;
  }): Observable<StrictHttpResponse<Group>> {

    const rb = new RequestBuilder(this.rootUrl, GroupService.GetNextGroupWithDetailsPath, 'get');
    if (params) {
      rb.path('groupId', params.groupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Group>;
      })
    );
  }

  /**
   * Get next group with details.
   *
   * Get next group with details.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getNextGroupWithDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNextGroupWithDetails(params: {
    groupId: number;
  }): Observable<Group> {

    return this.getNextGroupWithDetails$Response(params).pipe(
      map((r: StrictHttpResponse<Group>) => r.body as Group)
    );
  }

  /**
   * Path part for operation getPreviousGroupWithDetails
   */
  static readonly GetPreviousGroupWithDetailsPath = '/v1/groups/{groupId}/details/previous';

  /**
   * Get previous group with details.
   *
   * Get previous group with details.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPreviousGroupWithDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPreviousGroupWithDetails$Response(params: {
    groupId: number;
  }): Observable<StrictHttpResponse<Group>> {

    const rb = new RequestBuilder(this.rootUrl, GroupService.GetPreviousGroupWithDetailsPath, 'get');
    if (params) {
      rb.path('groupId', params.groupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Group>;
      })
    );
  }

  /**
   * Get previous group with details.
   *
   * Get previous group with details.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPreviousGroupWithDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPreviousGroupWithDetails(params: {
    groupId: number;
  }): Observable<Group> {

    return this.getPreviousGroupWithDetails$Response(params).pipe(
      map((r: StrictHttpResponse<Group>) => r.body as Group)
    );
  }

  /**
   * Path part for operation getLastGroupWithDetails
   */
  static readonly GetLastGroupWithDetailsPath = '/v1/groups/{groupId}/details/last';

  /**
   * Get last group with details.
   *
   * Get last group with details.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLastGroupWithDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLastGroupWithDetails$Response(params: {
    groupId: number;
  }): Observable<StrictHttpResponse<Group>> {

    const rb = new RequestBuilder(this.rootUrl, GroupService.GetLastGroupWithDetailsPath, 'get');
    if (params) {
      rb.path('groupId', params.groupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Group>;
      })
    );
  }

  /**
   * Get last group with details.
   *
   * Get last group with details.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLastGroupWithDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLastGroupWithDetails(params: {
    groupId: number;
  }): Observable<Group> {

    return this.getLastGroupWithDetails$Response(params).pipe(
      map((r: StrictHttpResponse<Group>) => r.body as Group)
    );
  }

  /**
   * Path part for operation getFirstGroupWithDetails
   */
  static readonly GetFirstGroupWithDetailsPath = '/v1/groups/{groupId}/details/first';

  /**
   * Get first group with details.
   *
   * Get first group with details.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFirstGroupWithDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFirstGroupWithDetails$Response(params: {
    groupId: number;
  }): Observable<StrictHttpResponse<Group>> {

    const rb = new RequestBuilder(this.rootUrl, GroupService.GetFirstGroupWithDetailsPath, 'get');
    if (params) {
      rb.path('groupId', params.groupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Group>;
      })
    );
  }

  /**
   * Get first group with details.
   *
   * Get first group with details.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFirstGroupWithDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFirstGroupWithDetails(params: {
    groupId: number;
  }): Observable<Group> {

    return this.getFirstGroupWithDetails$Response(params).pipe(
      map((r: StrictHttpResponse<Group>) => r.body as Group)
    );
  }

  /**
   * Path part for operation exportGroupDetails
   */
  static readonly ExportGroupDetailsPath = '/v1/groups/{groupId}/export';

  /**
   * Export group details.
   *
   * Export group details.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportGroupDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportGroupDetails$Response(params: {
    groupId: number;
    language?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, GroupService.ExportGroupDetailsPath, 'get');
    if (params) {
      rb.path('groupId', params.groupId, {});
      rb.query('language', params.language, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Export group details.
   *
   * Export group details.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportGroupDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportGroupDetails(params: {
    groupId: number;
    language?: string;
  }): Observable<void> {

    return this.exportGroupDetails$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
