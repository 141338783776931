import { HttpClientModule, HttpRequest } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SGWTConnectCore, SGWTConnectModule } from '@sgwt/connect-angular';
import { sgwtConnect } from 'src/sgwt-connect';
import { CashAccountService, CashAccountUserGroupService, CompanyService, SecurityAccountService, SecurityAccountUserGroupService, GroupService } from "src/Api/habilitation-front/services";

const sgwtConnectFactory = (): SGWTConnectCore => sgwtConnect;
const sgwtConnectPredicate = ({ url }: HttpRequest<any>): boolean => true;

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		BrowserAnimationsModule,
		SGWTConnectModule.forRoot(sgwtConnectFactory, sgwtConnectPredicate)
	],
	providers: [
		CashAccountService,
		CashAccountUserGroupService,
		CompanyService,
		GroupService,
		SecurityAccountService,
		SecurityAccountUserGroupService
	],

	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	bootstrap: [AppComponent]
})
export class AppModule { }
