/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BasicUser } from '../models/basic-user';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllUsers
   */
  static readonly GetAllUsersPath = '/v1/users';

  /**
   * Get users.
   *
   * Get users.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllUsers$Response(params?: {
  }): Observable<StrictHttpResponse<Array<BasicUser>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetAllUsersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BasicUser>>;
      })
    );
  }

  /**
   * Get users.
   *
   * Get users.
   *
   * **Authorization policy:**  
   *
   * scopes: openid, profile, api.gallery-custody-habilitation-front-demo.v1 ; end user with minimum authentication level L2
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllUsers(params?: {
  }): Observable<Array<BasicUser>> {

    return this.getAllUsers$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BasicUser>>) => r.body as Array<BasicUser>)
    );
  }

}
