export const environment = {
  production: true,
  ENABLE_SGCONNECT: true,
  environment: 'Production',
  APP_SG_CONNECT_ENDPOINT: '%#APP_SG_CONNECT_ENDPOINT#%',
  APP_SG_CONNECT_CLIENT_ID: '%#APP_SG_CONNECT_CLIENT_ID#%',
  APP_SG_CONNECT_SCOPE: '%#APP_SG_CONNECT_SCOPE#%',
  gcuSubscriptionFrontApiUrl: '%#gcuSubscriptionFrontApiUrl#%',
  gcuReferentialFrontApiUrl: '%#gcuReferentialFrontApiUrl#%',
  eClearingApiUrl:'%#eClearingApiUrl#%',
  gcuSecuritiesFrontApiUrl: '%#gcuSecuritiesFrontApiUrl#%',
  gcuUserFrontApiUrl: '%#gcuUserFrontApiUrl#%',
  gcuHabilitationFrontApiUrl: '%#gcuHabilitationFrontApiUrl#%',
  IsFeedBackOptionEnabled: true
};
