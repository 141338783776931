import { setupSGWTConnect, SGWTConnectCore } from '@sgwt/connect-angular';
import { environment } from 'src/environments/environment';

interface SgwtConnectHTMLElement extends HTMLElement {
	sgwtConnect: SGWTConnectCore;
	setSgwtConnectInstance: (sgwtConnectInstance: SGWTConnectCore) => void;
}

export const sgwtConnect = setupSGWTConnect({
	authorization_endpoint: environment.APP_SG_CONNECT_ENDPOINT,
	client_id: environment.APP_SG_CONNECT_CLIENT_ID,
	scope: environment.APP_SG_CONNECT_SCOPE,
	acr_values: 'L2'
});

const widget = document.querySelector<SgwtConnectHTMLElement>('sgwt-connect');
if (widget) {
	if (typeof widget.setSgwtConnectInstance === 'function') {
		widget.setSgwtConnectInstance(sgwtConnect);
	} else {
		const handleSgwtConnectReady = () => {
			widget.setSgwtConnectInstance(sgwtConnect);
			widget.removeEventListener('sgwt-connect--ready', handleSgwtConnectReady);
		};

		widget.addEventListener('sgwt-connect--ready', handleSgwtConnectReady);
	}
}
