import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import('./bootstrap').catch((err) => console.error(err));

import { LicenseManager } from 'ag-grid-enterprise/main';

LicenseManager.setLicenseKey(
    'Societe_Generale_Global_Solution_Centre_Pvt_Ltd_GLASS_6Devs10_April_2019__MTU1NDg1MDgwMDAwMA==404dd2b729f58ded830bd5eaade8ff75',
);
if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).catch((err) => console.error(err));
